import styled                                     from '@emotion/styled'
import CardMedia                                  from '@mui/material/CardMedia'
import { parse, format }                          from 'fecha'
import { Link }                                   from 'gatsby'
import React                                      from 'react'
import { ImageCard, ImageGrid, ImageTextOverlay } from '../components'
import { useDimensions }                          from '../context'
import { VideoPlayer }                            from '../features'
import { isMobile }                               from '../services/browser-utils'
import { playerDimensions }                       from '../services/media-utils'

const PlayerContainer = styled.div`
  grid-column: 1 / -1;
  margin-top: 10px;
  margin-bottom: 10px;

  @media only screen and (min-width: 1000px) {
    grid-column: 1 / span 3;
    grid-template-areas:
      'topLeft'
      '.';
  }
`

const playerOptions = {
  autoplay: false,
  muted: true,
  playbackRates: [ 0.5, 1, 1.5, 2 ],
  controls: true,
  fill: true,
  controlBar: {
    liveDisplay: true,
    pictureInPictureToggle: true,
  },
}

const MonthContent = ({ images, playlist, year, years, month }) => {
  const { height, width } = useDimensions()
  const mobile = isMobile(width)

  const playerParams = playlist.videos.map(({ hls }, index) => ({
    key: `month-playlist-${year}-${month}`,
    sources: [
      {
        src: hls,
        type: 'application/x-mpegURL',
        withCredentials: true,
      },
    ],
    poster: hls.replace('.m3u8', '.0000001.jpg'),
    overlays: [
      {
        content: `<span class="vjs-custom-overlay">${index + 1}/${playlist.videos.length}</span>`,
        showBackground: false,
        start: 'playing',
        end: 'pause',
        align: 'top-left',
      },
    ],
  }))

  const { playerWidth, playerHeight } = mobile
    ? playerDimensions({ vw: width, vh: height, margin: 10 })
    : playerDimensions({ vw: width, vh: height, margin: 25 })

  return (
    <ImageGrid>
      {playerParams.length > 0 && (
        <PlayerContainer>
          <VideoPlayer
            className='video-js vjs-default-skin vjs-big-play-centered'
            playerOptions={playerOptions}
            playerParams={playerParams}
            playerWidth={playerWidth}
            playerHeight={playerHeight}
          />
        </PlayerContainer>
      )}

      {images.map(({ small, meta }, index) => {
        const imageDate = parse(meta.date, 'YYYY-MM-DD')
        const formattedDate = format(imageDate, 'ddd Do MMM YYYY')

        return (
          <ImageCard key={small.key}>
            <Link
              to={`/app/image/${year}/${month}/${index}`}
              state={{ images, years }}
              aria-label={`go to image ${index} for ${year}-${month} page`}
            >
              <CardMedia
                className='lazyload'
                data-sizes='auto'
                component='img'
                image={small.url}
                alt={`Image ${index} for ${year}-${month}`}
                title={`Image ${index} for ${year}-${month}`}
                aria-label={`Image ${index} for ${year}-${month}`}
              />
              <ImageTextOverlay>
                <h1>{formattedDate}</h1>
                <span>{`${meta.time.substring(0, 2)}:${meta.time.substring(2, 4)}`}</span>
              </ImageTextOverlay>
            </Link>
          </ImageCard>
        )
      })}
    </ImageGrid>
  )
}

export { MonthContent }
