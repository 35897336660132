import { navigate }                        from 'gatsby'
import React, { useRef }                   from 'react'
import { Footer, Head, PageContainerGrid } from '../components'
import { LinksProvider, YearsProvider }    from '../context'
import { Header }                          from '../features'
import { monthNavigationLinks }            from '../services/media-utils'
import { MonthContent }                    from './month-content'

const MonthTemplate = ({ pageContext: { years, year, month, images, playlist } }) => {
  const tRef = useRef(null)

  const yearIndex = years ? years.findIndex(y => y.year === year) : null
  const { months } = years[yearIndex]
  const monthIndex = months ? months.findIndex(m => m === month.substring(0, 2)) : null
  const links = months ? monthNavigationLinks(monthIndex, months, year) : null

  return (
    <YearsProvider value={{ years }}>
      <Head title={`${year}-${month}`} />
      <LinksProvider value={{ links, navigate }}>
        <PageContainerGrid ref={tRef}>
          <Header />
          <main>
            <MonthContent
              images={images}
              playlist={playlist}
              year={year}
              years={years}
              month={month}
            />
          </main>
          <Footer tRef={tRef} />
        </PageContainerGrid>
      </LinksProvider>
    </YearsProvider>
  )
}

export default MonthTemplate
